/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'activity': {
      init: function() {
        // JavaScript to be fired on the about us page
        var ctx = document.getElementById("myChart").getContext('2d');

        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                datasets: [{
                  label: 'Actions',
                  data: [12, 19, 30, 50, 29, 23, 44, 22, 81, 49, 100,96],
                  borderColor: '#0B92AD',
                  borderWidth: 1,
                  backgroundColor: 'rgba(11, 146, 173, 0.25)',
                  
                },{
                  label: 'Karma',
                  data: [20,24,42,50,80,99,90,75,80,88,72,101],
                  borderColor: '#E4625F',
                  borderWidth: 2,
                  backgroundColor: 'rgba(228, 98, 95, 0.25)',
                  
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
        new Chart(document.getElementById("doughnut-chart"), {
            type: 'doughnut',
            data: {
              labels: ["Joined", "Waiting to Join"],
              datasets: [
                {
                  //label: "Population (millions)",
                  backgroundColor: ["#0B92AD", "#E4625F"],
                  data: [22,11]
                }
              ]
            },
            options: {
              title: {
                display: false,
                text: 'Users Invited vs. Users Joined'
              }
            }
        });

        new Chart(document.getElementById("email-chart"), {
            type: 'line',
            data: {
              labels: ["July", "August", "September", "October", "November", "December"],
              datasets: [{
                label: 'Emails',
                data: [44,22,81,49,100,96],
                borderColor: '#0B92AD',
                borderWidth: 1,
                backgroundColor: 'rgba(11, 146, 173, 0.3)',
              }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });

        new Chart(document.getElementById("call-chart"), {
            type: 'line',
            data: {
              labels: ["July", "August", "September", "October", "November", "December"],
              datasets: [{
                label: 'Calls',
                data: [44,22,81,49,100,96],
                borderColor: '#E4625F',
                borderWidth: 1,
                backgroundColor: 'rgba(228, 98, 95, 0.3)',
              }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });

        new Chart(document.getElementById("tweet-chart"), {
            type: 'line',
            data: {
              labels: ["July", "August", "September", "October", "November", "December"],
              datasets: [{
                label: 'Tweets',
                data: [44,22,81,49,100,96],
                borderColor: '#BF3DE5',
                borderWidth: 1,
                backgroundColor: 'rgba(191, 61, 229, 0.3)',
              }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });

        new Chart(document.getElementById("post-chart"), {
            type: 'line',
            data: {
              labels: ["July", "August", "September", "October", "November", "December"],
              datasets: [{
                label: 'Posts',
                data: [44,22,81,49,100,96],
                borderColor: '#25E098',
                borderWidth: 1,
                backgroundColor: 'rgba(37, 224, 152, 0.3)',
              }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
